import React, { useEffect, useState } from "react";
import {
  Grommet,
  Box,
  Button,
  Heading,
  Text,
  Paragraph,
  Spinner,
} from "grommet";
import { Alert } from "grommet-icons";
import { loadStripe } from "@stripe/stripe-js";
import background from "../background.png";
import theme from "../theme";

const stripePromise = loadStripe(process.env.REACT_APP_P_STRIPE);
const backendApi = process.env.REACT_APP_API_URL;

const Payment = () => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [checkoutState, setCheckoutState] = useState({});

  useEffect(() => {
    const authResult = new URLSearchParams(window.location.search);
    const internalCheckoutIntent = authResult.get("internalCheckoutIntent");
    if (!internalCheckoutIntent) {
      setError(true);
      setLoaded(true);
    } else {
      window.history.pushState({}, document.title, "/");
      async function validate() {
        try {
          const request = await fetch(
            `${backendApi}/payments/validate-checkout-session`,
            {
              method: "POST",
              body: JSON.stringify({
                internalCheckoutIntent,
              }),
            }
          );
          const res = await request.json();
          if (res.error) {
            setError(true);
            setLoaded(true);
          } else {
            setCheckoutState({ ...res });
            setLoaded(true);
          }
        } catch (err) {
          setError(true);
          setLoaded(true);
        }
      }
      validate();
    }
  }, []);
  const generateSession = async () => {
    const stripe = await stripePromise;
    stripe
      .redirectToCheckout({
        sessionId: checkoutState.CheckoutSessionId,
      })
      .then((d) => {})
      .catch((e) => {});
  };
  return (
    <Grommet full theme={theme}>
      <Box
        align="center"
        justify="center"
        pad="small"
        background={{
          color: "accent-4",
          image: `url('${background}')`,
        }}
        height="xlarge"
        flex={false}
        fill="vertical"
        direction="row"
        wrap
        overflow="auto"
      >
        {!loaded ? (
          <Spinner size="xlarge" />
        ) : error ? (
          <Box
            align="center"
            pad="small"
            background={{
              0: "b",
              1: "r",
              2: "a",
              3: "n",
              4: "d",
              color: "white",
              position: "bottom",
            }}
            round="medium"
            elevation="xlarge"
            margin="medium"
            direction="column"
            alignSelf="center"
            animation={{ type: "fadeIn", size: "medium" }}
          >
            <Box align="center" justify="center" pad="xsmall" margin="xsmall">
              <Box
                align="center"
                justify="center"
                pad="large"
                margin="medium"
                round="full"
              >
                <Alert color="brand" size="xlarge" />
              </Box>
              <Heading
                level="2"
                size="medium"
                margin="xsmall"
                textAlign="center"
              >
                Oops
              </Heading>
              <Paragraph size="medium" margin="medium" textAlign="center">
                There was an error trying to generate a checkout session for
                your payment, please go back to the Mariahi Xpress App and try
                again.
              </Paragraph>
            </Box>
          </Box>
        ) : (
          <Box
            align="center"
            pad="small"
            background={{
              0: "b",
              1: "r",
              2: "a",
              3: "n",
              4: "d",
              color: "white",
              position: "bottom",
            }}
            round="medium"
            elevation="xlarge"
            margin="medium"
            direction="column"
            alignSelf="center"
            animation={{ type: "fadeIn", size: "medium" }}
          >
            <Box align="center" justify="center" pad="xsmall" margin="xsmall">
              <Box
                align="center"
                justify="center"
                pad="large"
                margin="medium"
                background={{
                  dark: false,
                  color: "light-2",
                  image: `url('${checkoutState.Item.image}')`,
                }}
                round="full"
              />
              <Heading
                level="2"
                size="medium"
                margin="xsmall"
                textAlign="center"
              >
                {checkoutState.Item.title}
              </Heading>
              <Text textAlign="center">{checkoutState.Item.subtitle}</Text>
              <Paragraph size="small" margin="medium" textAlign="center">
                {checkoutState.Item.description}
              </Paragraph>
              <Box
                align="center"
                justify="center"
                pad="small"
                direction="row-responsive"
                flex
                alignSelf="center"
                basis="xxsmall"
                gap="small"
                margin="xsmall"
              >
                <Button
                  label={checkoutState.Item.buttonLabel}
                  primary
                  plain={false}
                  onClick={() => generateSession()}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Grommet>
  );
};

export default Payment;
