import React, { useState, useEffect } from "react";
import { Grommet, Box, Heading, Paragraph } from "grommet";
import { Checkmark } from "grommet-icons";
import theme from "../theme";
import background from "../background.png";

const Success = () => {
  const [error, setError] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const appUrl = queryParams.get("app_url");
    const sessionId = queryParams.get("session_id");
    if (!appUrl) {
      setError(true);
    } else {
      setTimeout(() => {
        if (sessionId) {
          window.location.href = `${appUrl}?session_id=${sessionId}`;
        } else {
          window.location.href = appUrl;
        }
      }, 2000);
    }
  }, []);
  return (
    <Grommet full theme={theme}>
      <Box
        align="center"
        justify="center"
        pad="small"
        background={{
          color: "accent-4",
          image: `url('${background}')`,
        }}
        height="xlarge"
        flex={false}
        fill="vertical"
        direction="row"
        wrap
        overflow="auto"
      >
        <Box
          align="center"
          pad="small"
          background={{
            0: "b",
            1: "r",
            2: "a",
            3: "n",
            4: "d",
            color: "white",
            position: "bottom",
          }}
          round="medium"
          elevation="xlarge"
          margin="medium"
          direction="column"
          alignSelf="center"
          animation={{ type: "fadeIn", size: "medium" }}
        >
          <Box align="center" justify="center" pad="xsmall" margin="xsmall">
            <Box
              align="center"
              justify="center"
              pad="large"
              margin="medium"
              round="full"
            >
              <Checkmark color="brand" size="xlarge" />
            </Box>
            <Heading level="2" size="medium" margin="xsmall" textAlign="center">
              Great!
            </Heading>
            <Paragraph size="medium" margin="medium" textAlign="center">
              Thanks for making the payment!
              <br />
              {!error
                ? "You will be redirected back to the Mariachi Xpress App."
                : "You can now close this window and go back to the Mariachi Xpress App."}
            </Paragraph>
          </Box>
        </Box>
      </Box>
    </Grommet>
  );
};

export default Success;
