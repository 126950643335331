import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Payment from "./components/Payment";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/cancel">
          <Cancel />
        </Route>
        <Route path="/success">
          <Success />
        </Route>
        <Route path="/">
          <Payment />
        </Route>
      </Switch>
    </Router>
  );
};
